
import { Box, Boxed, ButtonLayout, ButtonLink, ButtonPrimary, Checkbox, DateField, EmailField, Form, GridLayout, IconCheckedRegular, IconInformationUserRegular, Inline, IntegerField, LoadingBar, ResponsiveLayout, Select, skinVars, Stack, Switch, Text1, Text2, Text3, Text4, TextField, TextLink, Tooltip, useScreenSize } from '@telefonica/mistica';
import React, { useLayoutEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { Client } from '../../entities/clients/client';

import { useIonRouter } from '@ionic/react';
import moment from 'moment';
import { ListOption } from '../../entities/commons/list-option.model';
import { Contract } from '../../entities/contract/contract';
import { OperationContract } from '../../entities/contract/operations/operation-contract';
import { getMasterDatas, getProvinceCity, isValidContractData } from '../../utils/apiclient';
import { getUser, setOperationContract } from '../../utils/storage';
import { scrollToTop, trackError } from '../../utils/utils';
import { useAlert } from '../alerts/Alert';

interface ConfirmPersonalDataProps {
	idCard?: string,
	client?: Client,
	inProcessContract?: boolean,
    contract: Contract | null,
	loading?: boolean,
	onCancel:() => void
	onConfirm:() => void
}

const ConfirmPersonalData: React.FC<ConfirmPersonalDataProps> = (prop) => {
	const intl = useIntl()
	const screenSize = useScreenSize();
	const router = useIonRouter();
	const { showAlert } = useAlert()

	const [genderOptions, setGenderOptions] = React.useState([] as ListOption[])
	const [countryOptions, setCountryOptions] = React.useState([] as ListOption[])
	const [provinceOptions, setProvinceOptions] = React.useState([] as ListOption[])
	const [viaOptions, setViaOptions] = React.useState([] as ListOption[])
	const [employmentSituationOptions, setEmploymentSituationOptions] = React.useState([] as ListOption[])
	const [activitySectorOptions, setActivitySectorOptions] = React.useState([] as ListOption[])
	const [activitySectorOptionsInit, setActivitySectorOptionsInit] = React.useState([] as ListOption[])
	const [sourceMoneyOptions, setSourceMoneyOptions] = React.useState([] as ListOption[])

	const [visibleOtherActivitySector, setVisibleOtherActivitySector] = useState(false);
	const [inProcessContract, setInProcessContract] = useState(false);


	const [loading, setLoading] = React.useState(true);
	//Loading form
	const [loadingForm, setLoadingForm] = React.useState(false);

	//DataCardStep 0
	const [dataCardStep, setDataCardStep] = React.useState(0);
	const editDataCard= (cardStep:any) => {
	  setDataCardStep(cardStep);
	};

	const [formErrors, setFormErrors] = useState([] as any);
	const [disabledTaxAddressProvince, setDisabledTaxAddressProvince] = useState(false);
	const [disabledCorrespondenceAddressProvince, setDisabledCorrespondenceAddressProvince] = useState(false);


	useLayoutEffect(()=>{
		setLoading(false);

		if(prop?.inProcessContract){
			setInProcessContract(true);
		}

		getMasterDatas('genres').then(options=>{
			setGenderOptions(options);
		});
		getMasterDatas('countries').then(options=>{
			setCountryOptions(options);
		});
		getMasterDatas('provinces').then(options=>{
			setProvinceOptions(options);
		});
		getMasterDatas('addressTypes').then(options=>{
			setViaOptions(options);
		});
		getMasterDatas('laboralSituation').then(options=>{
			setEmploymentSituationOptions(options);
		});
		getMasterDatas('laboralSectors').then(options=>{
			setActivitySectorOptionsInit(options);
		});
		getMasterDatas('sourceMoney').then(options=>{
			setSourceMoneyOptions(options);
		});

		if(prop?.client?.conocimientoUsuario?.actividad != null){
			switch ((prop?.client?.conocimientoUsuario?.actividad || '').toLowerCase()) {
				case 'otros':
				case 'other':
				case 'ong':
				case 'ngo':
				  setVisibleOtherActivitySector(true);
				  break;
				default:
				  setVisibleOtherActivitySector(false);
				  break;
			}
		}
	}, []);


	//Form
	const user = getUser();

	const [formData, setFormData] = useState({
		idCard: prop?.client?.nif ?? prop?.idCard ?? '',

		//Personal data
		dateOfExpiry : !(prop?.client?.noCaducaNunca ?? false) ? prop?.client?.fechaCaducidadNIF ? (moment(prop?.client?.fechaCaducidadNIF).format("YYYY-MM-DD")) : (prop?.client?.fechaCaducidadTarjetaResidenteTS ? (moment(prop?.client?.fechaCaducidadTarjetaResidenteTS).format("YYYY-MM-DD")) : '') : '',
		neverExpires: prop?.client?.noCaducaNunca ?? false,

		name: prop?.client?.nombre ?? "",
		surname: prop?.client?.apellidos ?? "",
		birthDate: prop?.client && prop?.client.fechaNacimiento ? moment(prop?.client.fechaNacimiento).format("YYYY-MM-DD").toString() : '',
		countryOfBirth: prop?.client ? (prop?.client?.paisNacimiento?.toString() ?? '') : (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
		nationality: prop?.client ? (prop?.client?.nacionalidad?.toString() ?? '') : (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
		countryOfResidence: prop?.client ? (prop?.client?.paisResidencia?.toString() ?? '') : (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),

		gender: prop?.client?.genero ?? "",

		//Contact information
		telephone: prop?.client?.telefono ?? "",
		mobile: prop?.client?.movil ?? "",
		email: prop?.client?.email?.toLowerCase() ?? "",

		//Tax address
		taxAddressCountry: prop?.client ? (prop?.client?.datosFiscales?.domicilioPais?.toString() ?? '') : (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11"),
		taxAddressZipcode: prop?.client?.datosFiscales?.domicilioCP ?? "",
		taxAddressProvince: prop?.client?.datosFiscales?.domicilioProvincia?.toString().padStart(2, '0') ?? "",
		taxAddressLocality: prop?.client?.datosFiscales?.domicilioPoblacion ?? "",
		taxAddressVia: prop?.client?.datosFiscales?.domicilioTipoVia ?? "",
		taxAddressNameVia: prop?.client?.datosFiscales?.domicilioVia ?? "",
		taxAddressNumber: prop?.client?.datosFiscales?.domicilioNumero ?? "",
		taxAddressFloor: prop?.client?.datosFiscales?.domicilioPiso ?? "",

		taxAddressEqual: true,

		//Correspondence address
		correspondenceAddressCountry: process.env.REACT_APP_ID_PAIS_ESPANA ?? "11",
		correspondenceAddressZipcode: "",
		correspondenceAddressProvince: "",
		correspondenceAddressLocality: "",
		correspondenceAddressVia: "",
		correspondenceAddressNameVia: "",
		correspondenceAddressNumber: "",
		correspondenceAddressFloor: "",

		employmentSituation: prop?.client?.conocimientoUsuario?.ocupacion ?? "",
		activitySector: prop?.client?.conocimientoUsuario?.actividad ?? "",
		otherActivitySector: prop?.client?.conocimientoUsuario?.actividadEspecificar ?? "",
		sourceMoney: prop?.client?.conocimientoUsuario?.procedenciaPatrimonio ?? "",
		telefonicaGroupEmployee:prop?.client?.conocimientoUsuario?.empleadoGrupoTelefonica ?? false,
		usTaxIdentificationNumber:prop?.client?.conocimientoUsuario?.nifEEUU ?? false,
		personPublicResponsibility:prop?.client?.conocimientoUsuario?.personaResponsabilidadPublica ?? false,
		personPublicResponsibilityPosition: prop?.client?.conocimientoUsuario?.cargo ?? "",
		personPublicResponsibilityOrganism: prop?.client?.conocimientoUsuario?.organismo ?? "",
		familyPublicResponsibilityOrganism:prop?.client?.conocimientoUsuario?.familiarPEP ?? false,
		relatedPublicResponsibilityOrganism:prop?.client?.conocimientoUsuario?.allegadoPep ?? false,
		ongPositionLast2Years:user?.conocimientoUsuario?.ongPositionLast2Years ?? false,

		informationTrueVerifiable:false,
		legalOriginIncome:false,
		acceptPrivacyPolicy:false,
	});

	const handleZipcode = (evt:any) =>{
	  try{
		const { target } = evt;
		const { name, value } = target;
		const correspondenceAddressCountry = formData.correspondenceAddressCountry;

		if(value && value.toString().trim().length>0){
			if (correspondenceAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA){
			setLoadingForm(true);
			getProvinceCity(value.toString().trim()).then(zipData=>{
				if(zipData && zipData.locality && zipData.province){
				const province = provinceOptions.find(item=>item.descripcion===zipData.province);

				switch(name){
					case 'taxAddressZipcode':
					const newValuesTaxAddress = {
						...formData,
						['taxAddressLocality']: zipData.locality,
						['taxAddressProvince']: province?.valor ?? formData.taxAddressProvince
					};
					setFormData(newValuesTaxAddress);
					break;
					case 'correspondenceAddressZipcode':
					const newValuesCorrespondenceAddress = {
						...formData,
						['correspondenceAddressLocality']: zipData.locality,
						['correspondenceAddressProvince']: province?.valor ?? formData.correspondenceAddressProvince
					};
					setFormData(newValuesCorrespondenceAddress);
					break;
				}
				}
				setLoadingForm(false);
			});
			}
		}
	  }catch(err){
        if(err){
			trackError(JSON.stringify(err));
		  }
	  }
	}

  //Handle fields
  const handleError=(name:string) =>{
	if(formErrors && formErrors[name]){
	  const error = formErrors[name];
	  if(error){
		return intl.formatMessage({id: 'page_register_'+error.toLowerCase().replaceAll(' ', '_')});
	  }
	}
	return null;
  }

  const validateField = (name:string, value:any)=>{
	switch(name){
	  case 'name':
	  case 'surname':
		if(!value || value.length===0){
		  formErrors[name] = 'field required';
		  return false;
		}
		break;
	  case 'dateOfExpiry':
		const date = moment(value);
		const currentDate =  moment();
		if(date<currentDate){
		  formErrors[name] = 'the date has expired';
		  return false;
		}
		break;
	  case 'birthDate':
		const birthDate = moment(value);
		const legalDate =  moment().subtract(18, 'years');
		if(birthDate>legalDate){
		  formErrors[name] = 'is not legal age';
		  return false;
		}
		break;
	  case 'email':
		const expressionMail: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
		if(!expressionMail.test(value)){
		  formErrors[name] = 'is not a valid email';
		  return false;
		}
		break;
	}
	return true;
  }

  const handleChangeAnyField= (name:string, value:any) => {
	const newValues = {
	  ...formData,
	  [name]: value,
	};
	setFormData(newValues);

	if(handleError(name)){
	  delete formErrors[name];
	}

	switch(name){
	  case 'neverExpires':
		validateField('dateOfExpiry', formData.dateOfExpiry);
		if(value===true){
		  delete formErrors['dateOfExpiry'];
		}
		break;
	  case 'taxAddressCountry':
		const disabledTaxAddressProvince = value!=process.env.REACT_APP_ID_PAIS_ESPANA ?? "11";
		const newValuesTaxAddress = {
		  ...newValues,
		  ['taxAddressProvince']: disabledTaxAddressProvince ? '' : formData.taxAddressProvince
		};
		setFormData(newValuesTaxAddress);
		setDisabledTaxAddressProvince(disabledTaxAddressProvince);
		break;
	  case 'correspondenceAddressCountry':
		  const disabledCorrespondenceAddress = value!=process.env.REACT_APP_ID_PAIS_ESPANA ?? "11";
		  const newValuesCorrespondenceAddress = {
			...newValues,
			['correspondenceAddressProvince']: disabledCorrespondenceAddress ? '' : formData.correspondenceAddressProvince
		  };
		  setFormData(newValuesCorrespondenceAddress);
		  setDisabledCorrespondenceAddressProvince(disabledCorrespondenceAddress);
		  break;
	  case 'activitySector':
		  switch(value){
			case 'Otros':
			case 'Other':
			case 'ONG':
			case 'NGO':
			  setVisibleOtherActivitySector(true);
			  break;
			default:
			  newValues.otherActivitySector = '';
			  setFormData(newValues);
			  setVisibleOtherActivitySector(false);
			  break;
		  }
		  break;
	  case 'employmentSituation':
		  switch(value){
			case 'unemployed':
			case 'Householder':
			case 'Retired':
			case 'Student / Minor':
			case 'Desempleado':
			case 'Amo de casa':
			case 'Jubilado':
			case 'Estudiante/Menor de edad':
			  const activitySectorOptionsNone = activitySectorOptionsInit.filter(item=>item.valor==='None' || item.valor==='Ninguna');
			  const activitySectorNone = activitySectorOptionsNone.find(item=>item.valor==='None' || item.valor==='Ninguna');
			  setActivitySectorOptions(activitySectorOptionsNone);
			  const newValuesActivitySectorNone = {
				...newValues,
				['activitySector']: activitySectorNone ? activitySectorNone.valor : ''
			  };
			  setFormData(newValuesActivitySectorNone);
			  setVisibleOtherActivitySector(false);
			  break;
			default:
			  const activitySectorOptions = activitySectorOptionsInit.filter(item=>item.valor!=='None' && item.valor!=='Ninguna');
			  let activitySector = activitySectorOptions.find(item=>item.valor===formData.activitySector);


			  if(dataCardStep==0 && prop?.client?.conocimientoUsuario?.actividad){
				const option = activitySectorOptionsInit.find(item=>item.valor.toUpperCase()===(prop?.client?.conocimientoUsuario?.actividad?.toUpperCase()??''));
				if(option){
					activitySector=option;
				}
			  }

			  setActivitySectorOptions(activitySectorOptions);
			  const newValuesActivitySector = {
				...newValues,
				['activitySector']: activitySector ? activitySector.valor : ''
			  };
			  setFormData(newValuesActivitySector);
			  break;
		  }
		  break;
	  case 'personPublicResponsibility':
		if(!value){
			newValues.personPublicResponsibilityOrganism = '';
			newValues.personPublicResponsibilityPosition = '';
			setFormData(newValues);
		}
		break;
	}

	validateField(name, value);
  };

	const handleSubmit = (data: any): Promise<void> =>
		new Promise((resolve) => {
			setLoadingForm(true);

			if(dataCardStep<2){
				scrollToTop('form-confirm-data');
				setDataCardStep(dataCardStep+1);
				//Actualizamos datos de conocimiento del cliente
				if(dataCardStep==0){
					if(prop.inProcessContract && prop.contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && prop.contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS){
						const option= employmentSituationOptions.find(item=>item.valor==='Trabajo por cuenta propia' || item.valor==='Self-employment');
						if(option){
							setEmploymentSituationOptions([option]);
							handleChangeAnyField('employmentSituation', option.valor);
						}
					}else{
						if(prop?.client?.conocimientoUsuario?.ocupacion){
							const option= employmentSituationOptions.find(item=>item.valor.toUpperCase()===(prop?.client?.conocimientoUsuario?.ocupacion?.toUpperCase()??''));
							if(option){
								handleChangeAnyField('employmentSituation', option.valor);
							}
						}
					}
				}


				if(dataCardStep==1){
					if(prop?.client?.conocimientoUsuario?.procedenciaPatrimonio){
						const option= sourceMoneyOptions.find(item=>item.valor.toUpperCase()===(prop?.client?.conocimientoUsuario?.procedenciaPatrimonio?.toUpperCase()??''));
						if(option){
							handleChangeAnyField('sourceMoney', option.valor);
						}
					}
				}

				resolve();
				setLoadingForm(false);
			  }else{
				if(inProcessContract){
					//Validar

					let contractOperation:OperationContract = {
						...formData
					}

					isValidContractData(contractOperation).then((result)=>{
						if(result.result){

							setOperationContract(contractOperation);
							prop.onConfirm();
						  }else if(result.errors){
							const errors = result.errors as any;
							if(errors['dateOfExpiry'] || errors['name'] || errors['surname'] || errors['birthDate'] || errors['countryOfBirth'] || errors['nationality'] || errors['countryOfResidence'] || errors['gender']){
							  setDataCardStep(0);
							}else if(errors['telephone'] || errors['mobile'] || errors['email'] ||
									errors['taxAddressCountry'] || errors['taxAddressZipcode'] || errors['taxAddressCountry'] || errors['taxAddressProvince'] || errors['taxAddressLocality'] || errors['taxAddressVia'] || errors['taxAddressNameVia'] || errors['taxAddressNumber'] || errors['taxAddressFloor'] ||
									errors['correspondenceAddressCountry'] || errors['correspondenceAddressZipcode'] || errors['correspondenceAddressCountry'] || errors['correspondenceAddressProvince'] || errors['correspondenceAddressLocality'] || errors['correspondenceAddressVia'] || errors['correspondenceAddressNameVia'] || errors['correspondenceAddressNumber'] || errors['correspondenceAddressFloor']){
							  setDataCardStep(1);
							}else{
							  setDataCardStep(2);
							}
							setFormErrors(result.errors);
						  }
					}).finally(()=>{
						setLoadingForm(false);
						resolve();
					});
				}
			}


		});
	//Submit disabled
	const isSubmitDisabled = () => {

		const countryDefault = (process.env.REACT_APP_ID_PAIS_ESPANA ?? "11");
		switch(dataCardStep){
			case 0:
			  return inProcessContract ? false : (formData.idCard.length === 0 ||
					(!formData.neverExpires && (formData.dateOfExpiry.length===0 || handleError('dateOfExpiry')!==null )) ||
					formData.name.length === 0 || handleError('name')!==null ||
					formData.surname.length === 0 || handleError('surname')!==null ||
					formData.birthDate.length === 0 || handleError('birthDate')!==null ||
					formData.countryOfBirth.length === 0 || handleError('countryOfBirth')!==null ||
					formData.nationality.length===0 || handleError('nationality')!==null ||
					formData.countryOfResidence.length === 0 || handleError('countryOfResidence')!==null ||
					formData.gender.length === 0 || handleError('gender')!==null);
			case 1:
			  return inProcessContract ? (formData.taxAddressEqual!==true && (
				formData.correspondenceAddressCountry.length === 0 || handleError('correspondenceAddressCountry')!==null ||
				formData.correspondenceAddressZipcode.length === 0 || handleError('correspondenceAddressZipcode')!==null ||
				(formData.correspondenceAddressCountry===countryDefault && (formData.correspondenceAddressProvince.length === 0 || handleError('correspondenceAddressProvince')!==null)) ||
				formData.correspondenceAddressLocality.length === 0 || handleError('correspondenceAddressLocality')!==null ||
				formData.correspondenceAddressVia.length === 0 || handleError('correspondenceAddressVia')!==null ||
				formData.correspondenceAddressNameVia.length === 0 || handleError('correspondenceAddressNameVia')!==null ||
				formData.correspondenceAddressNumber.length === 0 || handleError('correspondenceAddressNumber')!==null
				)) : handleError('telephone')!==null ||
					formData.mobile.length === 0 || handleError('mobile')!==null ||
					formData.email.length === 0 || handleError('email')!==null ||
					formData.taxAddressCountry.length === 0 || handleError('taxAddressCountry')!==null ||
					formData.taxAddressZipcode.length === 0 || handleError('taxAddressZipcode')!==null ||
					(formData.taxAddressCountry===countryDefault && (formData.taxAddressProvince.length === 0 || handleError('taxAddressProvince')!==null)) ||
					formData.taxAddressLocality.length===0 || handleError('taxAddressLocality')!==null ||
					formData.taxAddressVia.length === 0 || handleError('taxAddressVia')!==null ||
					formData.taxAddressNameVia.length === 0 || handleError('taxAddressNameVia')!==null ||
					formData.taxAddressNumber.length === 0 || handleError('taxAddressNumber')!==null
					|| (formData.taxAddressEqual!==true && (
					  formData.correspondenceAddressCountry.length === 0 || handleError('correspondenceAddressCountry')!==null ||
					  formData.correspondenceAddressZipcode.length === 0 || handleError('correspondenceAddressZipcode')!==null ||
					  (formData.correspondenceAddressCountry===countryDefault && (formData.correspondenceAddressProvince.length === 0 || handleError('correspondenceAddressProvince')!==null)) ||
					  formData.correspondenceAddressLocality.length === 0 || handleError('correspondenceAddressLocality')!==null ||
					  formData.correspondenceAddressVia.length === 0 || handleError('correspondenceAddressVia')!==null ||
					  formData.correspondenceAddressNameVia.length === 0 || handleError('correspondenceAddressNameVia')!==null ||
					  formData.correspondenceAddressNumber.length === 0 || handleError('correspondenceAddressNumber')!==null
					  ));
			case 2:
			  return (inProcessContract && (formData.sourceMoney.length === 0)) ||
			  		formData.employmentSituation.length === 0 || handleError('employmentSituation')!==null ||
					formData.activitySector.length === 0 || handleError('activitySector')!==null ||
					((formData.activitySector==='Other' || formData.activitySector==='Otros' || formData.activitySector==='ONG' || formData.activitySector==='NGO') && formData.otherActivitySector.length===0) ||
					(formData.personPublicResponsibility===true && (formData.personPublicResponsibilityPosition.length === 0 || handleError('personPublicResponsibilityPosition')!==null || formData.personPublicResponsibilityOrganism.length === 0 || handleError('personPublicResponsibilityOrganism')!==null)) ||
					formData.informationTrueVerifiable===false ||
					formData.legalOriginIncome===false ||
					formData.acceptPrivacyPolicy===false;
		  }
	}

	return (
		<>{prop.loading ?
			<></>
		:
		<>
			<LoadingBar  visible={loading || loadingForm} />
			<Box paddingBottom={32}>
				<ResponsiveLayout className='form'>
					<Stack space={16}>
						<Boxed>
						<Box padding={32}>
							<Form id='form-confirm-data' autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>
							<Stack space={32}>
								<Inline space={'between'} alignItems='center'>
									<Inline space={8} alignItems='center'>
										<Text4 medium color={dataCardStep===0 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_personal_data'})} </Text4>
										{dataCardStep>0 &&
											<IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
										}
									</Inline>
									{dataCardStep>0 &&
										<ButtonLink onPress={()=>editDataCard(0)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
									}
								</Inline>
								{dataCardStep===0 &&
								<>
								<Stack space={16}>
									<GridLayout verticalSpace={16} template='6+6'
									left={
										<TextField fullWidth disabled={inProcessContract} readOnly={!inProcessContract} name="idCard" label={intl.formatMessage({id:'page_register_id_card'})} onChangeValue={(val:any)=> handleChangeAnyField('idCard', val)} />
									}
									right={
										<Stack space={8}>
										<DateField disabled={inProcessContract ? inProcessContract : formData.neverExpires} error={handleError('dateOfExpiry')!==null} helperText={handleError('dateOfExpiry')??undefined}  fullWidth name="dateOfExpiry" label={intl.formatMessage({id:'page_register_date_of_expiry'})} onChangeValue={(val:any)=> handleChangeAnyField('dateOfExpiry', val)} />
										<Checkbox disabled={inProcessContract} onChange={(val:any)=> handleChangeAnyField('neverExpires', val)} name="neverExpires" render={({controlElement, labelId}:any) => (
											<Inline alignItems="center" space={16}>
												{controlElement}
												<Text1 regular>{intl.formatMessage({id:'page_register_never_expires'})}</Text1>
											</Inline>
										)}></Checkbox>
										</Stack>
									}></GridLayout>

									<GridLayout verticalSpace={16} template='6+6'
									left={
										<TextField disabled={inProcessContract} fullWidth name="name" error={handleError('name')!==null} helperText={handleError('name')??undefined} label={intl.formatMessage({id:'page_register_name'})} onChangeValue={(val:any)=> handleChangeAnyField('name', val)} />
									}
									right={
										<TextField disabled={inProcessContract} fullWidth name="surname" error={handleError('surname')!==null} helperText={handleError('surname')??undefined} label={intl.formatMessage({id:'page_register_surname'})} onChangeValue={(val:any)=> handleChangeAnyField('surname', val)} />
									}></GridLayout>

									<GridLayout verticalSpace={16} template='6+6'
									left={
										<DateField disabled={inProcessContract} fullWidth name="birthDate" error={handleError('birthDate')!==null} helperText={handleError('birthDate')??undefined}  label={intl.formatMessage({id:'page_register_birthdate'})} onChangeValue={(val:any)=> handleChangeAnyField('birthDate', val)} />
									}
									right={
										<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_country_of_birth'})} name={'countryOfBirth'} error={handleError('countryOfBirth')!==null} helperText={handleError('countryOfBirth')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfBirth', val)}></Select>
									}></GridLayout>

									<GridLayout verticalSpace={16} template='6+6'
									left={
										<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_nationality'})} name={'nationality'} error={handleError('nationality')!==null} helperText={handleError('nationality')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('nationality', val)}></Select>
									}
									right={
										<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_country_of_residence'})} name={'countryOfResidence'} error={handleError('countryOfResidence')!==null} helperText={handleError('countryOfResidence')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('countryOfResidence', val)}></Select>
									}></GridLayout>


									<GridLayout verticalSpace={16} template='6+6'
									left={
										<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_gender'})} name={'gender'} error={handleError('gender')!==null} helperText={handleError('gender')??undefined} options={genderOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('gender', val)}></Select>
									}
									right={
										<></>
									}></GridLayout>

									<Box paddingTop={16}>
										<ButtonLayout align='left'>
										<ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue_to_contact_details'})}</ButtonPrimary>
										</ButtonLayout>
									</Box>
									</Stack>
								</>
								}
							</Stack>
							</Form>
						</Box>
						</Boxed>
						<Boxed>
							<Box padding={32}>
								<Form autoJump onSubmit={handleSubmit} initialValues={formData} className={loadingForm ? 'loading' : ''}>
									<Stack space={32}>
									<Inline space={'between'} alignItems='center'>
										<Inline space={8} alignItems='center'>
										<Text4 medium color={dataCardStep===1 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_contact_details_address'})} </Text4>
										{dataCardStep>1 &&
											<IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
										}
										</Inline>
										{dataCardStep>1 &&
										<ButtonLink onPress={()=>editDataCard(1)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
										}
									</Inline>
									{dataCardStep===1 &&
										<>
										<Stack space={16}>
											<GridLayout verticalSpace={16} template='6+6'
											left={
												<TextField disabled={inProcessContract} fullWidth name="telephone" optional error={handleError('telephone')!==null} helperText={handleError('telephone')??undefined} label={intl.formatMessage({id:'page_register_telephone'})} onChangeValue={(val:any)=> handleChangeAnyField('telephone', val)} />
											}
											right={
												<TextField disabled={inProcessContract} fullWidth name="mobile" error={handleError('mobile')!==null} helperText={handleError('mobile')??undefined} label={intl.formatMessage({id:'page_register_mobile'})} onChangeValue={(val:any)=> handleChangeAnyField('mobile', val)} />
											}></GridLayout>

											<GridLayout verticalSpace={16} template='6+6'
											left={
												<EmailField disabled={inProcessContract} fullWidth name="email" error={handleError('email')!==null} helperText={handleError('email')??undefined}  label={intl.formatMessage({id:'page_register_email'})} onChangeValue={(val:any)=> handleChangeAnyField('email', val)} maxLength={80} />
											}
											right={
												<></>
											}></GridLayout>

											<Box paddingTop={16}>
											<Text3 medium>{intl.formatMessage({id:'page_register_tax_residence'})}</Text3>
											</Box>

											<GridLayout verticalSpace={16} template='6+6'
											left={
												<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_country'})} name="taxAddressCountry" error={handleError('taxAddressCountry')!==null} helperText={handleError('taxAddressCountry')??undefined} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressCountry', val)}></Select>
											}
											right={
												<TextField disabled={inProcessContract} fullWidth name="taxAddressZipcode" error={handleError('taxAddressZipcode')!==null} helperText={handleError('taxAddressZipcode')??undefined} label={intl.formatMessage({id:'page_register_zipcode'})} maxLength={5} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressZipcode', val)} onBlur={handleZipcode} />
											}></GridLayout>

											<GridLayout verticalSpace={16} template='6+6'
											left={
												<Select fullWidth disabled={inProcessContract ? inProcessContract : disabledTaxAddressProvince}  value={formData.taxAddressProvince} label={intl.formatMessage({id:'page_register_province'})} name='taxAddressProvince' error={handleError('taxAddressProvince')!==null} helperText={handleError('taxAddressProvince')??undefined} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressProvince', val)}></Select>
											}
											right={
												<TextField disabled={inProcessContract} fullWidth value={formData.taxAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='taxAddressLocality' error={handleError('taxAddressLocality')!==null} helperText={handleError('taxAddressLocality')??undefined} maxLength={70}  onChangeValue={(val:any)=> handleChangeAnyField('taxAddressLocality', val)} ></TextField>
											}></GridLayout>

											<GridLayout verticalSpace={16} template='6+6'
											left={
												<Select disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_via'})} name="taxAddressVia" options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))} error={handleError('taxAddressVia')!==null} helperText={handleError('taxAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressVia', val)}></Select>
											}
											right={
												<TextField disabled={inProcessContract} fullWidth name="taxAddressNameVia" error={handleError('taxAddressNameVia')!==null} helperText={handleError('taxAddressNameVia')??undefined} label={intl.formatMessage({id:'page_register_name_via'})} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressNameVia', val)}  />
											}></GridLayout>

											<GridLayout verticalSpace={16} template='6+6'
											left={
												<GridLayout verticalSpace={16} template='8+4'
													left={
														<IntegerField disabled={inProcessContract} fullWidth label={intl.formatMessage({id:'page_register_number'})} name="taxAddressNumber"  error={handleError('taxAddressNumber')!==null} helperText={handleError('taxAddressNumber')??undefined} maxLength={5} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressNumber', val)}></IntegerField>
													} 
													right={
													<Tooltip
														targetLabel="help text"
														target={<IconInformationUserRegular size={18} color={skinVars.colors.brand}/>}
														position="top"
														description={intl.formatMessage({id:'page_register_number_tooltip'})}
													/>
												}/>
										
											}
											right={
												<TextField disabled={inProcessContract} optional fullWidth name="taxAddressFloor" label={intl.formatMessage({id:'page_register_floor'})}  error={handleError('taxAddressFloor')!==null} helperText={handleError('taxAddressFloor')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('taxAddressFloor', val)} maxLength={10}  />
											}></GridLayout>



											<Box paddingTop={16}>
											<Text3 medium>{intl.formatMessage({id:'page_register_correspondence_address'})}</Text3>
											</Box>

											<Checkbox onChange={(val:any)=> handleChangeAnyField('taxAddressEqual', val)} name="taxAddressEqual" render={({controlElement, labelId}:any) => (
													<Inline alignItems="center" space={16}>
													{controlElement}
													<Text1 regular>{intl.formatMessage({id:'page_register_tax_address_equal'})}</Text1>
													</Inline>
											)}></Checkbox>

											{!formData.taxAddressEqual &&
											<Stack space={16}>

												<GridLayout verticalSpace={16} template='6+6'
												left={
													<Select fullWidth label={intl.formatMessage({id:'page_register_country'})} name="correspondenceAddressCountry" onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressCountry', val)} options={countryOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
												}
												right={<TextField fullWidth name="correspondenceAddressZipcode" error={handleError('correspondenceAddressZipcode')!==null} helperText={handleError('correspondenceAddressZipcode')??undefined} label={intl.formatMessage({id:'page_register_zipcode'})} maxLength={formData.correspondenceAddressCountry === process.env.REACT_APP_ID_PAIS_ESPANA ? 5 : 10} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressZipcode', val)} onBlur={handleZipcode} />
												}></GridLayout>


												<GridLayout verticalSpace={16} template='6+6'
												left={
													<Select fullWidth disabled={disabledCorrespondenceAddressProvince} value={formData.correspondenceAddressProvince} label={intl.formatMessage({id:'page_register_province'})} name='correspondenceAddressProvince' error={handleError('correspondenceAddressProvince')!==null} helperText={handleError('correspondenceAddressProvince')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressProvince', val)} options={provinceOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
												}
												right={
													<TextField fullWidth value={formData.correspondenceAddressLocality} label={intl.formatMessage({id:'page_register_locality'})} name='correspondenceAddressLocality' error={handleError('correspondenceAddressLocality')!==null} helperText={handleError('correspondenceAddressLocality')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressLocality', val)} maxLength={70}></TextField>
												}></GridLayout>


												<GridLayout verticalSpace={16} template='6+6'
												left={
													<Select fullWidth label={intl.formatMessage({id:'page_register_via'})} name="correspondenceAddressVia" error={handleError('correspondenceAddressVia')!==null} helperText={handleError('correspondenceAddressVia')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressVia', val)} options={viaOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
												}
												right={
													<TextField fullWidth name="correspondenceAddressNameVia" error={handleError('correspondenceAddressNameVia')!==null} helperText={handleError('correspondenceAddressNameVia')??undefined}  label={intl.formatMessage({id:'page_register_name_via'})} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressNameVia', val)} />
												}></GridLayout>


												<GridLayout verticalSpace={16} template='6+6'
													left={
														<GridLayout verticalSpace={16} template='8+4'
															left={
																<IntegerField fullWidth label={intl.formatMessage({id:'page_register_number'})} name="correspondenceAddressNumber" error={handleError('correspondenceAddressNumber')!==null} helperText={handleError('correspondenceAddressNumber')??undefined}  maxLength={5} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressNumber', val)}></IntegerField>
															} 
															right={
															<Tooltip
																targetLabel="help text"
																target={<IconInformationUserRegular size={18} color={skinVars.colors.brand}/>}
																position="top"
																description={intl.formatMessage({id:'page_register_number_tooltip'})}
															/>
														}/>
													}
													right={
													<TextField optional fullWidth name="correspondenceAddressFloor" error={handleError('correspondenceAddressFloor')!==null} helperText={handleError('correspondenceAddressFloor')??undefined}  label={intl.formatMessage({id:'page_register_floor'})} onChangeValue={(val:any)=> handleChangeAnyField('correspondenceAddressFloor', val)} maxLength={10}  />
													}></GridLayout>
												</Stack>
											}

											<Box paddingTop={16}>
											<ButtonLayout align='left'>
												<ButtonPrimary disabled={isSubmitDisabled()} submit>{intl.formatMessage({id:'page_register_actions_continue_to_customer_knowledge'})}</ButtonPrimary>
											</ButtonLayout>
											</Box>

										</Stack>
										</>
									}
									</Stack>
								</Form>
							</Box>
						</Boxed>
						<Boxed>
						<Box padding={32}>
							<Form autoJump onSubmit={handleSubmit} initialValues={formData}>
							<Stack space={32}>
								<Inline space={'between'} alignItems='center'>
								<Inline space={8} alignItems='center'>
									<Text4 medium color={dataCardStep===2 ? skinVars.colors.buttonPrimaryBackground : skinVars.colors.neutralMedium}>{intl.formatMessage({id:'page_register_customer_knowledge'})} </Text4>
									{dataCardStep>2 &&
									<IconCheckedRegular size={24} color={skinVars.colors.buttonPrimaryBackground}></IconCheckedRegular>
									}
								</Inline>
								{dataCardStep>2 &&
									<ButtonLink onPress={()=>editDataCard(3)}>{intl.formatMessage({id:'page_register_actions_edit'})}</ButtonLink>
								}
								</Inline>
								{dataCardStep===2 &&
								<>
								<Stack space={16}>
									<GridLayout verticalSpace={16} template='6+6'
										left={
											<Select disabled={(prop.inProcessContract && prop.contract?.plan?.cdCompania===parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1') && prop.contract?.plan?.individual===process.env.REACT_APP_PLAN_PENSIONES_SIMPLIFICADOS)} fullWidth label={intl.formatMessage({id:'page_register_employment_situation'})} name="employmentSituation" error={handleError('employmentSituation')!==null} helperText={handleError('employmentSituation')??undefined} value={formData.employmentSituation} onChangeValue={(val:any)=> handleChangeAnyField('employmentSituation', val)} options={employmentSituationOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
										}
										right={
											<Stack space={16}>
											<Select fullWidth value={formData.activitySector} label={intl.formatMessage({id:'page_register_activity_sector'})} name="activitySector" error={handleError('activitySector')!==null} helperText={handleError('activitySector')??undefined} disabled={activitySectorOptions.length===0}  onChangeValue={(val:any)=> handleChangeAnyField('activitySector', val)} options={activitySectorOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
											{visibleOtherActivitySector &&
												<TextField fullWidth name="otherActivitySector" error={handleError('otherActivitySector')!==null} helperText={handleError('otherActivitySector')??undefined}  label={intl.formatMessage({id:'page_register_other_activity_sector'})} onChangeValue={(val:any)=> handleChangeAnyField('otherActivitySector', val)}  />
											}
											</Stack>
										}></GridLayout>

									{inProcessContract &&
										<GridLayout verticalSpace={16} template='6+6'
											left={
												<Select fullWidth value={formData.sourceMoney} label={intl.formatMessage({id:'page_register_source_money'})} name="sourceMoney" error={handleError('sourceMoney')!==null} helperText={handleError('sourceMoney')??undefined} onChangeValue={(val:any)=> handleChangeAnyField('sourceMoney', val)} options={sourceMoneyOptions.map(item => ({text:item.descripcion, value:item.valor}))}></Select>
											}
											right={<></>}></GridLayout>
									}

									<Box paddingY={8}>
									<Stack space={16}>
										<Switch onChange={(val:any)=> handleChangeAnyField('telefonicaGroupEmployee', val)} name="telefonicaGroupEmployee"  render={({controlElement}) => (
											<Inline alignItems="center" space="between">
											<Text2 medium >
												{intl.formatMessage({id:'page_register_telefonica_group_employee'})}
											</Text2>
											{controlElement}
											</Inline>
										)}></Switch>


										<Switch onChange={(val:any)=> handleChangeAnyField('usTaxIdentificationNumber', val)} name="usTaxIdentificationNumber" render={({controlElement}) => (
											<Inline alignItems="center" space="between">
												<Text2 medium >
												{intl.formatMessage({id:'page_register_us_tax_identification_number'})}
												</Text2>
												{controlElement}
											</Inline>
										)}></Switch>


										<Switch onChange={(val:any)=> handleChangeAnyField('personPublicResponsibility', val)} name="personPublicResponsibility" render={({controlElement}) => (
											<Inline alignItems="center" space="between">
												<Inline alignItems="center" space={8}>
												<Text2 medium >
													{intl.formatMessage({id:'page_register_person_public_responsibility'})}
												</Text2>
												<Tooltip
														targetLabel="help text"
														target={<IconInformationUserRegular />}
														position="top"
														description={intl.formatMessage({id:'page_register_person_public_responsibility_help_text'})}
													/>
												</Inline>
												{controlElement}
											</Inline>
										)}></Switch>

										{formData.personPublicResponsibility=== true &&
											<>
											<GridLayout verticalSpace={16} template='6+6'
											left={
											<TextField fullWidth name="personPublicResponsibilityPosition" error={handleError('personPublicResponsibilityPosition')!==null} helperText={handleError('personPublicResponsibilityPosition')??undefined}  label={intl.formatMessage({id:'page_register_person_public_responsibility_position'})} onChangeValue={(val:any)=> handleChangeAnyField('personPublicResponsibilityPosition', val)}/>
											}
											right={
											<TextField fullWidth name="personPublicResponsibilityOrganism" error={handleError('personPublicResponsibilityOrganism')!==null} helperText={handleError('personPublicResponsibilityOrganism')??undefined} label={intl.formatMessage({id:'page_register_person_public_responsibility_organism'})} onChangeValue={(val:any)=> handleChangeAnyField('personPublicResponsibilityOrganism', val)} />
											}></GridLayout>
											</>
										}


										<Switch onChange={(val:any)=> handleChangeAnyField('familyPublicResponsibilityOrganism', val)} name="familyPublicResponsibilityOrganism" render={({controlElement}) => (
											<Inline alignItems="center" space="between">
											<Inline alignItems="center" space={8}>
											<Text2 medium >
												{intl.formatMessage({id:'page_register_family_public_responsibility_organism'})}
											</Text2>
											<Tooltip
													targetLabel="help text"
													target={<IconInformationUserRegular />}
													position="top"
													description={intl.formatMessage({id:'page_register_family_public_responsibility_organism_help_text'})}
												/>
											</Inline>
											{controlElement}
										</Inline>
										)}></Switch>


										<Switch onChange={(val:any)=> handleChangeAnyField('relatedPublicResponsibilityOrganism', val)} name="relatedPublicResponsibilityOrganism" render={({controlElement}) => (
											<Inline alignItems="center" space="between">
											<Inline alignItems="center" space={8}>
											<Text2 medium >
												{intl.formatMessage({id:'page_register_related_public_responsibility_organism'})}
											</Text2>
											<Tooltip
													targetLabel="help text"
													target={<IconInformationUserRegular />}
													position="top"
													description={intl.formatMessage({id:'page_register_related_public_responsibility_organism_help_text'})}
												/>
											</Inline>
											{controlElement}
										</Inline>
										)}></Switch>

										<Switch onChange={(val:any)=> handleChangeAnyField('ongPositionLast2Years', val)} name="ongPositionLast2Years" render={({controlElement}) => (
											<Inline alignItems="center" space="between">
											<Inline alignItems="center" space={8}>
											<Text2 medium >
												{intl.formatMessage({id:'page_register_question_ong'})}
											</Text2>
											</Inline>
											{controlElement}
										</Inline>
										)}></Switch>
										</Stack>
									</Box>

									<Box paddingTop={16}>
										<Stack space={16}>
										<Checkbox onChange={(val:any)=> handleChangeAnyField('informationTrueVerifiable', val)} name="informationTrueVerifiable" render={({controlElement, labelId}:any) => (
											<Inline alignItems="center" space={16}>
												{controlElement}
												<Text3 regular id={labelId}>
												{intl.formatMessage({id:'page_register_information_true_verifiable'})}
												</Text3>
											</Inline>
										)}></Checkbox>

										<Checkbox onChange={(val:any)=> handleChangeAnyField('legalOriginIncome', val)} name="legalOriginIncome" render={({controlElement, labelId}:any) => (
											<Inline alignItems="center" space={16}>
												{controlElement}
												<Text3 regular id={labelId}>
												{intl.formatMessage({id:'page_register_legal_origin_income'})}
												</Text3>
											</Inline>
											)}></Checkbox>
											<div className='html-content contain-politics-text' dangerouslySetInnerHTML={{__html: intl.formatMessage({id:'page_register_politics_text'})}}></div>

											<Checkbox onChange={(val:any)=> handleChangeAnyField('acceptPrivacyPolicy', val)} name="acceptPrivacyPolicy" render={({controlElement, labelId}:any) => (
												<Inline alignItems="center" space={16}>
												{controlElement}
												<Text3 regular id={labelId}>
													{intl.formatMessage({id:'page_register_accept_privacy_policy'},{privacy_policy:<TextLink onPress={()=>{showAlert('_privacy_policy_')}}>{intl.formatMessage({id:'page_register_accept_privacy_policy_link_text'})}</TextLink>})}
												</Text3>
												</Inline>
											)}></Checkbox>
										</Stack>
									</Box>

									<Box paddingTop={16}>
										<ButtonLayout align='left'>
											<ButtonPrimary disabled={isSubmitDisabled()} submit>{inProcessContract ? 'Continuar' : intl.formatMessage({id:'page_register_actions_continue_keys_generation'})}</ButtonPrimary>
										</ButtonLayout>
									</Box>
									</Stack>
								</>
								}
							</Stack>
							</Form>
						</Box>
						</Boxed>
					</Stack>
				</ResponsiveLayout>
			</Box>
		</>}</>
	);
};

export default ConfirmPersonalData;