
// DownloadDocumentPage.js
import { useEffect, useState } from 'react';
import { useIonRouter } from '@ionic/react';
import { useIntl } from 'react-intl';
import { getDocumentPublic } from '../../../utils/apiclient';

const DownloadDocumentPage: React.FC = () => {
  const intl = useIntl();
  const router = useIonRouter();
  const [documentUrl, setDocumentUrl] = useState<string>(''); 

  useEffect(() => {
    const downloadDocument = async () => {      
      try {
        const url = await getDocumentPublic(router.routeInfo.search.replace('?documentid=', '')); 
        setDocumentUrl(url); 
      } catch (error) {
        router.push('/error');
      }
    };

    downloadDocument();
  }, [router]);

  return (
    <div>
      <iframe
        src={documentUrl}
        style={{ width: '100%', height: '100vh', border: 'none' }}
        title="Documento"
      ></iframe>
    </div>
  );
};

export default DownloadDocumentPage;