import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { isPlatform } from "@ionic/react";
import isbot from "isbot";
import moment from "moment";
import ReactGA from 'react-ga4';
import { Client } from "../entities/clients/client";
import { OpcionMenu } from "../entities/clients/opcion-menu";
import { PlanComisionControl } from "../entities/clients/plan-comision-control";
import { PlanPromotor } from '../entities/clients/plan-promotor';
import { Contract } from "../entities/contract/contract";
import { Product } from "../entities/products/product";
import LoginPage from "../pages/private/login/LoginPage";
import GlobalPositionControlCommisionPage from "../pages/private/users/control-commision/global-position/GlobalPositionControlCommisionPage";
import GlobalPositionCustomerPage from "../pages/private/users/customer/global-position/GlobalPositionCustomerPage";
import GlobalPositionPromoterPage from "../pages/private/users/promoter/global-position/GlobalPositionPromoterPage";
import SelectProfile from "../pages/private/users/select-profile/SelectProfile";
import { isValidToken, trackStadistics } from "./apiclient";
import { getCurrentLang } from "./locate";
import { getOptionNavigationInverse, getUrlNavigation } from "./navigation";
import { getUser, removeUser } from "./storage";

export const isBot = isbot(navigator.userAgent);

export const isIOS = isPlatform("ios");
export const isAndroid = isPlatform("android");

export const isMobile = isPlatform("mobileweb");

export const isNativeApp = (isPlatform("ios") || isPlatform("android")) && !isPlatform("mobileweb");

export const getPlatform = () => {return (isNativeApp ? 'APP' : 'WEB')};
export const getPlatformDetail = () => {return (isNativeApp ? (isPlatform("ios") ? 'ios' : (isPlatform("android") ? 'android' : 'APP')) : 'web')};

export const haveRol = (rolename:string) =>{
    const user = getUser();
    return user && user.roles && user.roles.find(role=>role===rolename);
}

export const isClient = () => {
    const user = getUser();
    return user && user.roles && user.roles.find(role=>role==='client') && user.selectedRole==='client';
}
export const isPromoter = () => {
    const user = getUser();
    return user && user.roles && user.roles.find(role=>role==='promoter') && user.selectedRole==='promoter';
}
export const isControlCommision = () => {
    const user = getUser();
    return user && user.roles && user.roles.find(role=>role==='control-commision') && user.selectedRole==='control-commision';
}

export const havePermission = (optionMenu?:string, appMenu?:OpcionMenu[])=>{
    if(!appMenu){
        const user = getUser();
        appMenu = user && user.appMenu ? user.appMenu : [];
    }
    return appMenu.find(item=>item.cdMenu===parseInt(optionMenu??'')) ? true : false;
}

export const getUrlDefaultUser = () =>{
    let page = LoginPage;
    if(isValidToken()){
        const user = getUser();
        if(user && user.roles && user.roles.length>0){
            if(user.roles.length>1){
                page = SelectProfile;
            }else if (isClient()){
                page= GlobalPositionCustomerPage;
            }else if (isPromoter()){
                page= GlobalPositionPromoterPage;
            }else if (isControlCommision()){
                page= GlobalPositionControlCommisionPage;
            }
        }
    }else{
        removeUser();
    }
    return getUrlNavigation(page);
};


export const getCapitalizeText = (str?:string) =>{
    if(str){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }
        return splitStr.join(' ');
    }
    return str;
}
export const getInitials = (str?:string) =>{
    if(str){
        var splitStr = str.toLowerCase().split(' ');
        for (var i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase();
        }
        return splitStr.join('');
    }
    return str;
}

export const maskAccountNumber = (value: string): string =>{
    const accountNumber = value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
    return '**** '+accountNumber.substring(accountNumber.length-4);
}

export const translateProduct = (product:Product, messages:any): Product=>{
    let translatePrefix='';
    switch(product.cdCompania){
        case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_PENSIONES ?? '1'):
            translatePrefix='maintenance_pension_plans_'+product.cdCompania;
            break;
        case parseInt(process.env.REACT_APP_COMPANIA_FONDITEL_FONDOS ?? '3'):
            translatePrefix='maintenance_investment_funds_'+product.cdCompania;
            break;
    }

    const prefixProduct = translatePrefix+'_'+(product.cdFondo)+'_'+(product.cdPlan)+'_';

    for(const [key, info] of Object.entries(messages)){
        if(key.indexOf(prefixProduct+'categoria')!==-1){
            product.categoria = info as string;
        }
        if(product?.informacionGeneral){
            if(key.indexOf(prefixProduct+'tipofondo')!==-1){
                product.informacionGeneral.tipoFondo = info as string;
            }
            if(key.indexOf(prefixProduct+'gestora')!==-1){
                product.informacionGeneral.gestora = info as string;
            }
            if(key.indexOf(prefixProduct+'depositaria')!==-1){
                product.informacionGeneral.depositaria = info as string;
            }
            if(key.indexOf(prefixProduct+'otrosdatos')!==-1){
                product.informacionGeneral.otrosDatos = info as string;
            }
            if(key.indexOf(prefixProduct+'aportaciones')!==-1){
                product.informacionGeneral.aportaciones = info as string;
            }
            if(key.indexOf(prefixProduct+'comisiongestiondeposito')!==-1){
                product.informacionGeneral.comisionGestionDeposito = info as string;
            }
            if(key.indexOf(prefixProduct+'traspasosfondos')!==-1){
                product.informacionGeneral.traspasosFondos = info as string;
            }
            if(key.indexOf(prefixProduct+'comisionresultadosp')!==-1){
                product.informacionGeneral.comisionResultadosP = info as string;
            }
            if(key.indexOf(prefixProduct+'datosnuevos1')!==-1){
                product.informacionGeneral.datosNuevos1 = info as string;
            }
            if(key.indexOf(prefixProduct+'datosnuevos2')!==-1){
                product.informacionGeneral.datosNuevos2 = info as string;
            }
            if(key.indexOf(prefixProduct+'fechaintegracionfondo')!==-1){
                product.informacionGeneral.fechaIntegracionFondo = info as string;
            }
            if(key.indexOf(prefixProduct+'tituloareapublica')!==-1){
                product.informacionGeneral.tituloAreaPublica = info as string;
            }
            if(key.indexOf(prefixProduct+'titulocomposicionequipogestor')!==-1){
                product.informacionGeneral.tituloComposicionEquipoGestor = info as string;
            }
            if(key.indexOf(prefixProduct+'descripcioncortaareapublica')!==-1){
                product.informacionGeneral.descripcionCortaAreaPublica = info as string;
            }
            if(key.indexOf(prefixProduct+'subtitulocomposicionequipogestor')!==-1){
                product.informacionGeneral.subtituloComposicionEquipoGestor = info as string;
            }
            if(key.indexOf(prefixProduct+'descripcionareapublica')!==-1){
                product.informacionGeneral.descripcionAreaPublica = info as string;
            }
            if(key.indexOf(prefixProduct+'descripcioncomposicionequipogestor')!==-1){
                product.informacionGeneral.descripcionComposicionEquipoGestor = info as string;
            }
            if(key.indexOf(prefixProduct+'prestaciones')!==-1){
                product.informacionGeneral.prestaciones = info as string;
            }
            if(key.indexOf(prefixProduct+'movilizacionderechos')!==-1){
                product.informacionGeneral.movilizacionDerechos = info as string;
            }
            if(key.indexOf(prefixProduct+'modalidad')!==-1){
                product.informacionGeneral.modalidad = info as string;
            }
            if(key.indexOf(prefixProduct+'promotor')!==-1){
                product.informacionGeneral.promotor = info as string;
            }
            if(key.indexOf(prefixProduct+'fondoadscrito')!==-1){
                product.informacionGeneral.fondoAdscrito = info as string;
            }
            if(key.indexOf(prefixProduct+'comisioncontrol')!==-1){
                product.informacionGeneral.comisionControl = info as string;
            }
            if(key.indexOf(prefixProduct+'contingenciascubiertas')!==-1){
                product.informacionGeneral.contingenciasCubiertas = info as string;
            }
            if(key.indexOf(prefixProduct+'pifa')!==-1){
                product.informacionGeneral.pifa = info as string;
            }
            if(key.indexOf(prefixProduct+'racc')!==-1){
                product.informacionGeneral.racc = info as string;
            }
            if(key.indexOf(prefixProduct+'efectividadderechos')!==-1){
                product.informacionGeneral.efectividadDerechos = info as string;
            }
            if(key.indexOf(prefixProduct+'supuestosexcepcionales')!==-1){
                product.informacionGeneral.supuestosExcepcionales = info as string;
            }
            if(key.indexOf(prefixProduct+'indicadorriesgo')!==-1){
                product.informacionGeneral.indicadorRiesgo = info as string;
            }
            if(key.indexOf(prefixProduct+'politicainversion')!==-1){
                product.informacionGeneral.politicaInversion = info as string;
            }
            if(key.indexOf(prefixProduct+'prestacionesincapacidad')!==-1){
                product.informacionGeneral.prestacionesIncapacidad = info as string;
            }
            if(key.indexOf(prefixProduct+'derechosconsolidados')!==-1){
                product.informacionGeneral.derechosConsolidados = info as string;
            }
            if(key.indexOf(prefixProduct+'accesodocumentacion')!==-1){
                product.informacionGeneral.accesoDocumentacion = info as string;
            }
            if(key.indexOf(prefixProduct+'regimenfiscal')!==-1){
                product.informacionGeneral.regimenFiscal = info as string;
            }
            if(key.indexOf(prefixProduct+'limiteanual')!==-1){
                product.informacionGeneral.limiteAnual = info as string;
            }
            if(key.indexOf(prefixProduct+'limiteaportaciones')!==-1){
                product.informacionGeneral.limiteAportaciones = info as string;
            }
            if(key.indexOf(prefixProduct+'dependencia')!==-1){
                product.informacionGeneral.dependencia = info as string;
            }
            if(key.indexOf(prefixProduct+'formapagoprestaciones')!==-1){
                product.informacionGeneral.formaPagoPrestaciones = info as string;
            }
        }
    }

    return product;
}

export const formatContract = (value: Contract): string =>{
    return value.numContrato+'@'+(value.plan ? formatProduct(value.plan) : '');
}

export const formatProduct = (value?: Product|null): string =>{
    if(!value || value===null)
        return '';
    return value.cdCompania+'@'+value.cdPlan+'@'+value.cdFondo+'@'+value.cdSubplan;
}

export const formatPlanPromotor = (value: PlanPromotor): string =>{
    return value.compania+'@'+value.codigo_plan+'@'+value.codigo_fondo+'@'+value.codigo_subplan;
}

export const extractPlanPromotor = (value: string): PlanPromotor|null =>{
    if(value){
        const values = value.split('@');
        const planPromotor: PlanPromotor = {
            compania: parseInt(values[0]),
            codigo_plan: values[1],
            codigo_fondo: values[2],
            codigo_subplan: values[3]
        }
        return planPromotor;
    }
    return null;
}

export const formatPlanComisionControl = (value: PlanComisionControl): string =>{
    return value.cdCompania+'@'+value.cdPlan+'@'+value.cdFondo+'@'+value.cdSubplan;
}

export const extractPlanComisionControl = (value: string): PlanComisionControl|null =>{
    if(value){
        const values = value.split('@');
        const planComisionControl: PlanComisionControl = {
            cdCompania: parseInt(values[0]),
            cdPlan: values[1],
            cdFondo: values[2],
            cdSubplan: values[3]
        }
        return planComisionControl;
    }
    return null;
}

export const formatAccountNumber = (value: string): string =>{
    return value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
}

export const formatNetAssetValue = (value: number | string): string => {
    const valueNumber = typeof value === 'string' ? parseFloat(value.replace(',', '.')) : value;
    const result = valueNumber.toFixed(8).replace('.', ',');
    return result;
};
export const formatAmount = (amount: number | string, emptyDecimals:boolean=false): string => {
    if(typeof amount === 'undefined') {
      return '';
    }

    const amountNumber = typeof amount === 'string' ? parseFloat(amount.replace(',', '.')) : amount;
    let result = amountNumber.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    if(emptyDecimals){
        result = result.replace(',00', '');
    }

    return result;
  };


export const formatBalance = (balance: number | string): string => {
    if(typeof balance === 'undefined') {
      return '';
    }

    const balanceNumber = typeof balance === 'string' ? parseFloat(balance.replace(',', '.')) : balance;
    const result = balanceNumber.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    return result;
  };



export const formatAnualProfit = (anualProfit: Number| string): string => {
    if(typeof anualProfit === 'string' || typeof anualProfit === 'number'){
        const anualProfitNumber = typeof anualProfit === 'string' ? parseFloat(anualProfit.replace(',', '.')) : anualProfit;
        if(anualProfitNumber === 0){
            return anualProfitNumber.toString();
        }else{
            /*const [integerPart, decimalPart] = anualProfitNumber.toString().replace('.', ',').split(',');
            const roundedDecimalPart = Math.round(parseInt(decimalPart)) ;
            const formattedDecimalPart = roundedDecimalPart.toString().slice(0, 2);
            const formattedNumber = `${integerPart},${formattedDecimalPart}`;
            return formattedNumber;*/
            return anualProfitNumber ? anualProfitNumber.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.') : '0,00';
        }
    }
    return '0,00';
}

export const formatDate = (date: Date | string | undefined): string => {
    if(date)
        return moment(date).format('DD/MM/YYYY');

    return '';
}

export const validateUTCCodificationDate = (date: any) => {
    // Añadimos a la fecha mas tiempo, el moment.unix funciona con el UTC del sistema y no se puede modificar.
    return moment.unix(date.timestamp).add(5, 'hour').format("YYYY-MM-DD").toString();
};

export const validHttpUrl=(str:string, base?:string): boolean =>{
    if(!str || str.length===0){
        return false;
    }
    let url;

    try {
      url = new URL(str, base ? base : undefined);
    } catch (err) {
        if(!base){
            return validHttpUrl(str, window.location.origin);
        }
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};

export const isExternalUrl=(str:string): boolean =>{
    let url;

    try {
      url = new URL(str);
    } catch (err) {
        try{
            url = new URL(str, window.location.origin);
        } catch (error) {}
    }
    return (url?.host ?? '')!==window.location.host || getOptionNavigationInverse(url?.pathname??'')===undefined;
}

export const validatePhoneNumber = (numero: string) => {
    const phoneRegex = /^\+?\s?[1-9]\d{0,2}(\s?\d{3}){0,6}$/;
    return phoneRegex.test(numero);
  };

export const replaceDotWithComma = (text: string| undefined) => {
    if (typeof text === 'string' || typeof text === 'number') {
        return text.toString().replace(/\./g, ',');
    } 
    
    return '';
  };

export const firstCapitalLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

export const fileToBase64 = (file: File | Blob): Promise<string> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result as string);
        };

        reader.readAsDataURL(file);
        reader.onerror = reject;
});



export const groupProducts = (products: any[]): any[][] => {
    if(products){
        return products.reduce((groupCard: any[][], card: any, i: number) => {
        if (i % 2=== 0) {
            groupCard.push(products.slice(i, i + 2));
        }
        return groupCard;
        }, []);
    }else{
        return [];
    }
  }

export const closeDialog = (): Promise<void> =>
    new Promise((resolve) => {
        var interval = setInterval(function() {
            const closeButton = document.querySelector('div[data-component-name="Dialog"] button:first-child');
            if(closeButton){
                closeButton && (closeButton as HTMLElement).click();
            }else{
                clearInterval(interval)
                resolve();
            }
          }, 100)
});

export const fonditel_search_in_options = (options: any[], value: string | number, field: string = 'valor'): any | null => {
    if (options && Array.isArray(options)) {
      for (const option of options) {
        const opt = option as Record<string, any>;
        if (opt[field] && String(opt[field]).toUpperCase() === String(value).toUpperCase()) {
          return option;
        }
      }
    }
    return null;
};

export const vibration = ()=>{
    try{
        if(isNativeApp){
            Haptics.impact({ style: ImpactStyle.Light });
        }
    }catch(err){
        if(err){
            trackError(JSON.stringify(err));
        }
    }
}

export const getUserId= (user?:Client)=>{
    user = user ? user : getUser();

    if(user){
        return user.nif?.toString()?.replaceAll(/[^0-9]/g, '') ?? undefined;
    }
    return undefined;
}


export const trackEvent = (name:string, params?: any)=>{
    name  = name.replaceAll('-', '_').replaceAll(' ', '_').replaceAll('@', '_');
    try{
        let userId = getUserId();
        if(!params){
            params = {}
        }
        if(isNativeApp){
            if(name.length>40){
                name = name.substring(0, 40);
            }
            params.screenName= document.title;
            FirebaseAnalytics.logEvent({
                name: name,
                params: params ? params : {},
            });

            if(name==='login' && userId){
                FirebaseCrashlytics.setUserId({userId:userId});
                FirebaseAnalytics.setUserId({ userId: userId});
                FirebaseAnalytics.setUserProperty({
                    key: 'language',
                    value: getCurrentLang(),
                });
            }

        }else{
            if(name==='login' && userId){
                params.userId = userId;
            }
            ReactGA.event(name, params);
        }
    }catch(err){
        if(err){
            trackError(JSON.stringify(err));
        }
    }
}

export const trackPageView = (pathname:string)=>{
    try{
        setTimeout(function (){
            trackStadistics('PAGE');
            if(isNativeApp){
                FirebaseAnalytics.setCurrentScreen({
                    screenName: document.title,
                    screenClassOverride: getOptionNavigationInverse(pathname)?.props.children.type.name.toString(),
                });
            }
        }, 400);
    }catch(err){
        if(err){
            trackError(JSON.stringify(err));
        }
    }
}

export const trackUser = (user:Client)=>{
    try{
        let userId = getUserId(user);
        if(userId){
            userId = parseInt(userId).toString();

            if(isNativeApp){
                FirebaseCrashlytics.setUserId({userId:userId});
                FirebaseAnalytics.setUserId({ userId: userId});
                FirebaseAnalytics.setUserProperty({
                    key: 'language',
                    value: getCurrentLang(),
                });
            }else{
                ReactGA.event('login', {userId: userId});
            }
        }
    }catch(err){

        if(err){
            trackError(JSON.stringify(err));
        }
    }
}


export const trackError = (message:string)=>{
    try{
        if(isNativeApp){
            FirebaseCrashlytics.recordException({message})
        }
    }catch(err){

    }
}



export const scrollToTop = (id?:string) : Promise<void> =>
        new Promise((resolve) => {
            let element = document.getElementById("header") ? document.getElementById("header") : document.getElementById("header-private");

            if(id){
                element = document.getElementById(id) ? document.getElementById(id) : element;


                if(id==='search-results'){
                    document.querySelector('#search-results')?.scrollIntoView()
                }
                else{

                    /*if(smooth){
                        element?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                    }else{*/
                        let content = document.querySelector("ion-content");
                        content?.scrollToPoint(0, (element?.offsetTop ?? 0) - 50);
                    //}
                }
            }else{
                element?.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
            }
            resolve();
  });